import React from 'react'
import Link from 'gatsby-link'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import { Form, Field } from 'react-final-form'
import 'whatwg-fetch'
import { graphql } from 'gatsby'
import Layout from '../layouts/index'

const API_URL = 'https://api.mobelop.com/api/v1/contact-form'
// const API_URL = 'http://localhost:8080/api/v1/contact-form';

const ContactFormField = ({ input, meta, ...rest }) => {
  return (
    <label>
      {rest.title}
      <input {...input} type="text" />
      {meta.touched && meta.error && (
        <span className="form-error" style={{ display: 'block' }}>
          {meta.error}
        </span>
      )}
    </label>
  )
}

const ContactFormTextArea = ({ input, meta, ...rest }) => {
  return (
    <label>
      {rest.title}
      <textarea {...input} rows={rest.rows} />
      {meta.touched && meta.error && (
        <span className="form-error" style={{ display: 'block' }}>
          {meta.error}
        </span>
      )}
    </label>
  )
}

const required = (value) => (value ? undefined : 'Required')
const email = (value) =>
  value.indexOf('@') !== -1 ? undefined : 'Please enter your email address'
const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    )

class Contact extends React.Component {
  constructor() {
    super()
    this.state = {
      submitted: false,
    }
  }

  header() {
    return (
      <div className="medium-12 medium-offset-0 small-10 small-offset-1 cell">
        <h3 className="text-center">Need help with your project?</h3>
        <p className="text-center">
          Need an eCommerce expert to take care of your project?
          <br />
          {/*You can give us a call on{' '}*/}
          {/*<a href="tel:+421904616603">+421 904 616 603</a> or email us at{' '}*/}
          {/*<a href="mailto:info@mobelop.com">info@mobelop.com</a>*/}
          <br />
        </p>
      </div>
    )
  }

  onSubmit = (values) => {
    fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    }).then(() => {
      this.setState({ submitted: true })
    })
  }

  validate = () => {}

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')

    if (this.state.submitted) {
      return (
        <div>
          <Helmet title={siteTitle} />

          <div className="pagehead">
            <div className="pagehead__wrap black small" />
          </div>

          <div className="callout white">
            <div className="grid-container">
              <div className="grid-x">
                <div
                  className="medium-12 medium-offset-0 small-10 small-offset-1 cell"
                  style={{ minHeight: '50vh' }}
                >
                  <h2>Thank you!</h2>
                  <p>Once we'll get a second - we will get back to you</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={siteTitle} />

          <div className="pagehead">
            <div className="pagehead__wrap black small" />
          </div>

          <div className="callout white">
            <div className="grid-container">
              <div className="grid-x">{this.header()}</div>
              <Form
                onSubmit={this.onSubmit}
                validate={this.validate}
                render={({ handleSubmit, submitting, pristine, invalid }) => (
                  <div className="grid-x grid-padding-x">
                    <div className="medium-6 medium-offset-3 small-10 small-offset-1">
                      <form onSubmit={handleSubmit}>
                        {submitting && <div className="loading" />}
                        <div className="grid-x grid-padding-x">
                          <div className="medium-6 cell">
                            <Field
                              name="name"
                              title="Name"
                              validate={required}
                              render={ContactFormField}
                            />
                          </div>

                          <div className="medium-6 cell">
                            <Field
                              name="email"
                              title="Email"
                              validate={composeValidators(required, email)}
                              render={ContactFormField}
                            />
                          </div>

                          <div className="medium-12 cell">
                            <Field
                              name="company"
                              title="Company"
                              validate={required}
                              render={ContactFormField}
                            />
                          </div>

                          <div className="medium-12 cell">
                            <Field
                              name="message"
                              title="Message"
                              rows="6"
                              validate={required}
                              render={ContactFormTextArea}
                            />
                          </div>

                          <div className="medium-12 cell text-center">
                            <button
                              type="submit"
                              className="button large"
                              disabled={submitting || invalid || pristine}
                            >
                              Send Message
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Contact

export const pageQuery = graphql`
  query ContactQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
